import React from 'react';
import css from './Logo.module.css'

export default function LightLogo() {
  return (
    <div className={css.logo}>
      <svg
        width="102"
        height="36"
        viewBox="0 0 357 124"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 99.696H20.6832V79.0128H22.0224C24.7008 93.744 34.5216 101.184 51.7824 101.184C74.9952 101.184 87.6432 86.0064 87.6432 62.496C87.6432 38.688 75.144 23.6592 52.824 23.6592C35.1168 23.6592 26.04 32.9344 23.5104 45.4336H22.32V0H0V99.696ZM44.0448 80.7984C29.9088 80.7984 22.32 75.888 22.32 62.9424V61.752C22.32 48.8064 29.76 44.0448 44.1936 44.0448C58.4784 44.0448 65.0256 48.8064 65.0256 62.496C65.0256 76.0368 58.4784 80.7984 44.0448 80.7984ZM91.7601 62.6449C91.7601 89.1313 108.723 101.333 133.573 101.333C157.232 101.333 173.005 90.024 173.005 73.8048V72.168H150.685V73.656C150.685 80.0544 146.072 83.4768 132.829 83.4768C118.395 83.4768 113.039 78.5664 112.443 67.1088H173.154C173.2 66.7147 173.247 66.335 173.292 65.9647L173.293 65.9561L173.293 65.9512C173.538 63.9506 173.749 62.2225 173.749 59.9664C173.749 36.6048 157.53 23.808 133.127 23.808C108.575 23.808 91.7601 38.8368 91.7601 62.6449ZM112.741 55.3536C113.783 45.6816 119.437 41.2176 132.68 41.2176C145.775 41.2176 151.727 45.5328 152.322 55.3536H112.741ZM229.418 98.8527H201.89L167.964 24.3039H192.813L215.133 77.1279H216.621L239.09 24.3039H263.493L229.418 98.8527ZM275.401 124H287.087C305.042 124 314.589 119.691 321.43 104.09L356.77 24.3039H332.972L319.435 58.1797L313.734 75.8605H312.309L306.324 58.3283L291.219 24.3039H266.994L301.907 99.0387C300.482 102.307 298.202 103.793 292.929 103.793H275.401V124Z"
          fill="white"
        />
      </svg>
    </div>
  );
}
